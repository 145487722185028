import { Component } from '@angular/core';

@Component({
	template: `
		<div class="container">
			<h1>Oops! Page Not Found</h1>
			<p>
				The page you are looking for might have been removed or is
				temporarily unavailable.
			</p>
			<a href="index.html" class="btn">Go Back Home</a>
		</div>
	`,
	styles: `
		.container {
			text-align: center;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		h1 {
			color: #333;
			font-size: 3rem;
			margin-bottom: 10px;
		}
		p {
			color: #666;
			font-size: 1.2rem;
			margin-bottom: 20px;
		}
		.btn {
			display: inline-block;
			padding: 10px 20px;
			background-color: #007bff;
			color: #fff;
			text-decoration: none;
			border-radius: 5px;
		}
		.btn:hover {
			background-color: #0056b3;
		}
	`,
})
export class ErrorComponent {}
