import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { Router } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { distinctUntilChanged } from 'rxjs';
import { SharedModule } from "../../../shared.module";
import { SessionUser } from '../../../../utils/session-user.abstract';
import { SignInComponent } from '../../signIn/signIn.component';

@Component({
	selector: 'app-mobile-nav-bar',
	standalone: true,
	imports: [
		CommonModule,
		DialogModule,
		SharedModule,
		SignInComponent
	],
	templateUrl: './nav-bar.component.html',
	styleUrl: '../../header/header.style.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavBarComponent {
	public isAuth$ = signal(false);

	public visible = false;

	constructor(private router: Router) {
		SessionUser.isAuth.pipe(distinctUntilChanged()).subscribe({
			next: (value) => {
				this.isAuth$.set(value);
				if(value){
					this.visible = false;
				}
			}
		});
	}

	public showDialog(): void {
		this.visible = true;
	}

	public navigateToHome(): void {
		this.router.navigate(['']);
	}

	public navigateToProfile(): void {
		this.router.navigate(['/profile']);
	}

	public navigateToCreateEvent(): void {
		this.router.navigate(["/event/create"]);
	}
}
